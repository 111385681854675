/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .break{
        display: none;
    }
    .display-5 {
        font-size: calc(1.2rem + 2.1vw);
    }
    .prograInFooterContainer {
        padding: 3em 1em;
    }
    .prograInFooter{
        padding: 0;
    }
    .copyright {
        justify-content: center;
        margin-bottom: 1em;
        gap: 0.5em;
    }
    .socialmediaConatiner {
        justify-content: start;
        gap: 1em;
        flex-direction: column;
    }
    .customNavLink{
        border-right: unset;
        border-bottom: 1px solid #ff58544d;
    }
    .customNavLink a i{
        font-size: 16px;
    }
    .customNavLink:last-child {
        background-color: transparent;
    }
    /* .customNavLink.active:last-child a {
        color: #000;
    } */
    button.slick-arrow.slick-next {
        display: none !important;
    }
    .patchForMobileContainer {
        display: flex;
        padding: 0.5em 1em;
        gap: 0.8em;
        background-color: #28282B;
        justify-content: flex-end;
    }
    a.CloseNavigationMobile {
        position: absolute;
        top: 0.8em;
        display: block;
        right: 0.8em;
        font-size: 1.2em;
        font-weight: 600;
    }
    .CloseNavigationMobile i {
        color: #f60000;
    }
    .patchForMobileContainer a {
        background-color: transparent;
        text-decoration: none;
        width: auto;
        height: auto;
        padding: 0px;
    }
    .patchForMobileContainer a i {
        color: #fff;
        background: transparent;
        width: auto;
        font-weight: 200 !important;
        line-height: 1 !important;
        font-size: 0.85em;
    }
    .footerHeadText02, .footerHeadText {
        font-size: 1em;
    }
    button.navbar-toggler {
        border: none;
    }
    .custom-nav-logo img {
        width: 9.5em;
    }
    .prograIn-navbar .main-menu{
        justify-content: space-between;
    }
    .FooterAboutus, .FooterLegalPrivacy, .FooterGitTouch {
        margin-bottom: 1em;
        gap: 0;
    }
    .footerParaText {
        font-size: 0.8em;
    }
    .copyright .line{
        display: none;
    }
    /* .copyright .br{
        display: block;
    } */
    div#navbarNav {
        background: #fff;
        top: 0px;
        position: absolute;
        left: 0;
        width: 70vw;
        padding: 3.5em 0em;
        height: 100vh;
        animation: easeInAnimation-right 0.25s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
    .customNavLink {
        width: auto;
        justify-content: flex-start;
    }
    .customNavLink a {
        font-weight: 700;
        padding: 0.2em;
        color: #111;
        display: flex;
        justify-content: space-between;
    }
    .customNavLink.active {
        background: #fff;
        transform: scale(1);
        width: auto;
        border-right: none;
        flex-wrap: wrap;
    }
    button:focus:not(:focus-visible) {
        box-shadow: none;   
    }
    .customNavLink.active a {
        color: var(--secondary-color);
        /* font-weight: 700;
        font-size: 1.1em; */

    }
    .prograInCopyrightContainer {
        width: auto;
        padding: 0;
    }
    .socialmediaLinksContainer {
        gap: 0.5em;
    }
    .homePageContainer {
        overflow: auto;
    }
    .socialmediaLinksContainer{
        margin-bottom: 0.5rem;
    }
    .about .image{
        flex: unset;
      }
      .UpcomingEvents .box-container {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
      }
      .heading h2 {
        font-size: 2em;
      }
      .about .heading h2 {
        font-size: 2em;
      }
      section {
        padding: 3rem 0;
    }
    .customNavLink {
        padding: unset;
    }
    .customNavLink a{
        padding: 12px 15px;
        width: 100%;
        text-align: left;
    }
    .sub-menu{
        width: 100%;
        position: unset;
    }
    .customNavLink.active .sub-menu a{
        color: #fff;
    }
    .sub-menu li a{
        border-bottom: .1rem solid rgb(255 255 255 / 17%);
    }
    .sliderForHeaderContainer .slider_item{
        flex-direction: column-reverse;
    }
    .slider_item .banner-desc{
        margin-bottom: 1.5rem;
    }
    .slider_item .banner-desc div{
        padding: 1rem;
    }
    .slider_item{
        margin-top: 1.5rem;
    }
    .slider_item img{
        padding: 0 1rem;  
    }
    .slider_item .banner-desc br{
        display: none;
    }
    .taglineText p {
        font-size: 16px;
    }
    .vid-bg {
        height: 230px;
    }
    .ReadyDeploy .itembox{
        flex-wrap: wrap;
        justify-content: center;
    }
    .ReadyDeploy .itembox h2 br{
        display: none;
    }
    .ReadyDeploy .main-btn{
        margin-top: 1rem;
    }
    .ReadyDeploy .itembox h2{
        text-align: center;
    }
    .ratio {
        position: relative;
        width: 100%;
    }
    .borderLayout.top {
        top: -5px;
        left: 5px;
    }
    .overlap-content .videoRatio {
        width: 100%;
    }
    .overlap-end .overlap-content {
        left: 26%;
    }
    .borderLayout.bottom {
        bottom: -1px;
        left: -5px;
    }
    .ReadyDeploy .main-btn {
        margin-left: unset;
    }
    .videoDesc .display-4{
        font-size: calc(1rem + 1.5vw);
        line-height: 1.2;
    }
    .videoDesc .display-5 {
        font-size: calc(0.8rem + 2.1vw);
        font-weight: 300;
        line-height: 1.2;
    }
    .videoDesc{
        padding-bottom: 1rem;
    }
    .ATC .centerElements{
        justify-content: center;
        text-align: center;
    }
    .ATC .centerElements br{
        display: none;
    }
    .centerElements h2{
    margin-bottom: 1.5rem;
    }
    .Contextual h2{
        margin-bottom: 1.5rem;
        text-align: center;
    }
    .Contextual .overlap-end .overlap-content {
        right: 26%;
    }
    .Contextual .overlap-end {
        margin-bottom: 4rem;
    }
    .CrossDevice .centerElements{
        justify-content: center;
    }
    .ExploreAudience .display-4{
        font-size: calc(1.5rem + 1.5vw);
        line-height: 1.2;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    .ExploreAudience h2 br{
        display: none;
    }
    .ExploreAudience .overlap-end .overlap-content {
        right: 26%;
    }
    .ExploreAudience h3{
        font-size: calc(0.8rem + 1.5vw);
        margin-bottom: 0.8rem;
    }
    .ExploreAudience .centerElements{
        margin-top: 4rem;
    }
    .prograInFooter .partnersBrand-logo{
        margin-top: 1.5rem;
    }
    .main-menu .navbar-nav{
        border-top: 1px solid #666666;
        height: 100%;
        overflow-y: auto;
    }
    .contactfl-navigation-sticky-button{
        display: none;
    }
    .whatsapp-navigation-sticky-button{
        display: none;
    }
    .sticky-buttons {
        display: flex;
    }
    .InnerBanner.gee1I{
        padding: 265px 0 50px;
    }
    .InnerBanner .odzpY{
        clip-path: polygon(0 0, 100% 0%, 100% 45%, 0 0%);
    }
    .InnerBanner h4{
        font-size: calc(1.15rem + .3vw);
    }
    h4{
        font-size: calc(1.15rem + .3vw);
    }
    .br {
        display: none;
    }
    ._ECS_box .desc {
        font-size: 1.12rem;
    }
    ._ECS_SOT_box ._SOT .item p {
        font-size: 1.12rem;
        margin-bottom: 0;
    }
    ._ECS_DSP_box .item:first-child {
        width: 3.5rem;
    }
    ._ECS_box .F7NNY span{
        width: 3.5rem;
    height: 3.5rem;
    }
    ._SOT.FI svg {
        width: 52px !important;
    }
    .new-how-work .how-it-work-shape {
        background-size: 70% auto;
        min-height: 310px;
    }
    .target{
        text-align: center;
    }
    .target-pent{
    background-size: 82% auto;
    min-height: 450px;
    margin-top: 0;
    padding: 190px 0 0 0;
    }
    .youtube-intro .display-5{
        margin-bottom: 1rem!important;
    }
    .intro-para h3{
        text-align: center;
        font-size: 1.3rem;
    }
    .youtube-intro{
        padding-bottom: 3rem!important;
    }
    .new-how-work .how-it-work-shape{
        margin-top: 10px;
    }
    ._ECS_SOT_box ._SOT .item:first-child {
        width: 3.5rem;
    }
    .aboutInfo .logopng.bottom img {
        min-width: 5em;
    }
    .contactInfoDiv{
        margin-right: 0rem;
        margin-bottom: 3rem;
    }
    ._ECS_box .F7NNY{
        margin-top: -46px;
    }
    .ECS_Features_Tbl .table>:not(caption)>*>* {
        padding: 0.5rem;
    }
    .ECS_Features_Tbl thead th{
        font-size: 1rem;
    }
    .PSF ._ECS_box .planDesc{
        flex-wrap: wrap;
    }
    .PSF ._ECS_box .comLogo{
        flex-wrap: wrap; 
    }
    .PSF ._ECS_box .comLogo img{
        width: 6em;
    }
    .PSF ._ECS_box .F7NNY{
        flex-wrap: wrap;
        width: auto; 
    }
    .ACS .col-md-4{
        margin-bottom: 3rem;
    }
    .ACS .col-md-4:last-child{
        margin-bottom: 0rem;
    }
    .AS .ASImg {
        padding: 0 2rem;
        margin-bottom: 2rem;
    }
    .how-it-work-shape-div {
        padding: 0px 0rem;
    }
    .how-it-work-shape-div .flex-container img {
        width: 3em;
    }
    .how-it-work-shape-div .flex-container h4 {
        font-size: 1.5rem;
    } 
    .how-it-work-shape-div .brandLogo{
        margin-top: 2rem!important;
    }
    .PSF .spacingM {
        margin-top: 3.6rem;
    }
    .PSF .spacingSecond{
        margin-top: 3.6rem;
    }
    .PSF ._ECS_box .number {
        font-size: 4rem;
        line-height: 1;
        font-weight: 600;
    }
    .PSF br{
        display: none;
    }
    .PSF ._ECS_box .planDesc .conten {
        width: 100%;
        border-bottom: 1px solid #0000002e;
    }
    .PSF ._ECS_box .planDesc .conten:last-child{
        border-bottom: none;
    }
    .ECS .CMSLSTNODE{
        margin-bottom: 0!important;
    }
    .abtCollabLogo{
        padding: 0 0rem;
        margin-top: 1rem;
    }
    .abtCollabLogo .mb-4{
        margin-bottom: 1rem!important;
    }
    .customNavLink.nav-item.mega-menu:hover {
        position: relative;
    }
    .megamenu{
        padding: 10px 15px;
        position: static;
        box-shadow: unset;
        border: none;
    }
    .megamenu ul li a{
        justify-content: flex-start;
    }
    .megamenu .col{
        padding: 5px 10px;
    }
    .customNavLink{
        flex-flow: wrap;
    }
    .sub-menu li a{
        justify-content: flex-start;
        border-bottom: 1px solid #ff58544d;
    }
    .partnerSec .parner_logo {
        width: 60%;
        margin-top: 1.5rem;
    }
    .divReverse{
        flex-direction: column-reverse;
    }
    .CTSec .col-lg-4{
        margin-bottom: 1.5rem;
    }
    .gnagt {
        margin: 2.5rem 0;
    }
    .platformSec img{
        width: 100%;
        height: auto;
    }
    .platformSec .partnersBrand-logo .item {
        flex-basis: calc(32% - 0.2rem);
    }
    .platformSec .partnersBrand-logo {
        margin-bottom: 1.5rem;
    }
    .platformSec .partnersBrand-logo .item img {
        width: 100%;
    }
    .boxDiv .col-lg-3{
        margin-bottom: 1.5rem;
    }
    .popup-inner{
        max-width: 85%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    .prograInFooterContainer {
        padding: 1.5em 1em;
    }
    .copyright {
        justify-content: center;
        margin-bottom: 1em;
        gap: 0.5em;
    }
    .socialmediaConatiner {
        justify-content: start;
        gap: 1em;
        flex-direction: column;
    }
    .customNavLink.active:last-child {
        background: #f60000;
        transform: scale(1);
        width: auto;
        border-right: none;
    }
    /* .customNavLink.active:last-child a {
        color: #000;
    } */
    .customNavLink:last-child {
        background-color: transparent;
    }
    .customNavLink:last-child a {
        color: #f60000;
    }
    .patchForMobileContainer {
        display: flex;
        padding: 0.5em 1em;
        gap: 0.8em;
        background-color: #28282B;
        justify-content: flex-end;
    }
    a.CloseNavigationMobile {
        position: absolute;
        top: 0.8em;
        display: block;
        right: 0.8em;
        font-size: 1.2em;
        font-weight: 600;
    }
    button.slick-arrow.slick-next {
        display: none !important;
    }
    .CloseNavigationMobile i {
        color: #f60000;
    }
    .patchForMobileContainer a {
        background-color: transparent;
        text-decoration: none;
        width: auto;
        height: auto;
        padding: 0px;
    }
    .patchForMobileContainer a i {
        color: #fff;
        background: transparent;
        width: auto;
        font-weight: 200 !important;
        line-height: 1 !important;
        font-size: 0.85em;
    }
    p.footerParaText02 {
        font-size: 0.95em;
    }
    .footerHeadText02, .footerHeadText {
        font-size: 1em;
    }
    button.navbar-toggler {
        border: none;
    }
    /* .custom-nav-logo img {
        width: 2.5em;
    } */
    .FooterAboutus, .FooterLegalPrivacy, .FooterGitTouch {
        margin-bottom: 1em;
        gap: 0;
    }
    .footerParaText {
        font-size: 0.8em;
    }
    div#navbarNav {
        background: #fff;
        top: 0px;
        position: absolute;
        left: 0;
        width: 70vw;
        padding: 3.5em 0em;
        height: 100vh;
        animation: easeInAnimation-right 0.25s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
    .customNavLink {
        width: auto;
        justify-content: flex-start;
    }
    .customNavLink a {
        font-weight: 700;
        padding: 0.2em;
        color: #111;
        justify-content: flex-start;
    }
    .customNavLink.active {
        background: #fff;
        transform: scale(1);
        width: auto;
        border-right: none;
    }
    button:focus:not(:focus-visible) {
        box-shadow: none;   
    }
    .customNavLink.active a {
        color: #fce322;
        font-weight: 700;
        font-size: 1.1em;
    }
    .prograInCopyrightContainer {
        width: auto;
        padding: 0;
    }
    .socialmediaLinksContainer {
        gap: 0.5em;
    }
    .homePageContainer {
        overflow: auto;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .prograInFooterContainer {
        padding: 2em 1em;
    }
    .copyright {
        justify-content: center;
        margin-bottom: 1em;
        gap: 0.5em;
    }
    .socialmediaConatiner {
        justify-content: start;
        gap: 1em;
        flex-direction: column;
    }
    .customNavLink:last-child {
        background-color: transparent;
    }
    button.slick-arrow.slick-next {
        display: none !important;
    }
    .customNavLink:last-child a {
        color: #f60000;
    }
    .customNavLink.active:last-child {
        background: #f60000;
        transform: scale(1);
        width: auto;
        border-right: none;
    }
    /* .customNavLink.active:last-child a {
        color: #fff;
    } */
    .patchForMobileContainer {
        display: flex;
        padding: 0.5em 1em;
        gap: 0.8em;
        background-color: #28282B;
        justify-content: flex-end;
    }
    a.CloseNavigationMobile {
        position: absolute;
        top: 0.8em;
        display: block;
        right: 0.8em;
        font-size: 1.2em;
        font-weight: 600;
    }
    .CloseNavigationMobile i {
        color: #f60000;
    }
    .patchForMobileContainer a {
        background-color: transparent;
        text-decoration: none;
        width: auto;
        height: auto;
        padding: 0px;
    }
    .patchForMobileContainer a i {
        color: #fff;
        background: transparent;
        width: auto;
        font-weight: 200 !important;
        line-height: 1 !important;
        font-size: 0.85em;
    }
    .prograIn-navbar .main-menu{
        justify-content: space-between;
    }
    p.footerParaText02 {
        font-size: 0.95em;
        width: 90%;
        text-align: left !important;
    }
    .footerHeadText02, .footerHeadText {
        font-size: 1em;
    }
    button.navbar-toggler {
        border: none;
    }
    .custom-nav-logo img {
        width: 10.5em;
    }
    .slider_item{
        margin-top: 3rem;
    }
    .vid-bg {
        height: 275px;
    }
    .display-4 {
        font-size: calc(1.2rem + 2.7vw);
    }
    .display-5 {
        font-size: calc(1rem + 2.1vw);
    }
    .videoDesc{
        margin-bottom: 1rem;
    }
    .overlap-content .videoRatio {
        width: 60%;
    }
    .borderLayout.bottom {
        bottom: 0px;
        left: -6px;
    }
    .borderLayout.top {
        top: -6px;
        left: 7px;
    }
    .Contextual .overlap-end .overlap-content {
        right: 0%;
    }
    .ExploreAudience .overlap-end .overlap-content {
        right: 0%;
    }
    .ATC .centerElements p{
        display: block!important;
    }
    .Contextual .centerElements .section-title{
        display: block!important;
    }
    .CrossDevice .centerElements p{
        display: block!important;
    }
    .ExploreAudience .centerElements .section-title{
        display: block!important;
    }
    .prograInFooter .pages {
        gap: 0rem;
        flex-flow: column;
    }
    .FooterAboutus, .FooterLegalPrivacy, .FooterGitTouch {
        margin-bottom: 1em;
        gap: 0;
    }
    .footerParaText {
        font-size: 0.8em;
    }
    div#navbarNav {
        background: #fff;
        top: 0px;
        position: absolute;
        left: 0;
        width: 60vw;
        padding: 3.5em 0em;
        height: 100vh;
        animation: easeInAnimation-right 0.25s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
    .customNavLink {
        width: auto;
        justify-content: flex-start;
    }
    .customNavLink a {
        font-weight: 700;
        padding: 0.2em;
        color: #111;
        justify-content: flex-start;
    }
    .customNavLink.active {
        background: #fff;
        transform: scale(1);
        width: auto;
        border-right: none;
    }
    button:focus:not(:focus-visible) {
        box-shadow: none;   
    }
    .customNavLink.active a {
        color: #fce322;
        font-weight: 700;
        font-size: 1.1em;
    }
    .prograInCopyrightContainer {
        width: auto;
        padding: 0;
    }
    .socialmediaLinksContainer {
        gap: 0.5em;
    }
    .homePageContainer {
        overflow: auto;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    /* .prograIn-navbar {
        background-color: #fce322;
        position: sticky;
        top: 0;
        justify-content: end;
        z-index: 1;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    } */

    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}

@media screen and (min-width: 1500px) and (max-width: 1920px) {
    .vid-bg {
        height: 600px;
    }
    .customNavLink{
        font-size: 13px;
    }
    .custom-nav-logo img {
        width: 15em;
    }
}