:root {
  --main-color:#fce322;
  --secondary-color: #ff5854;
  --primary-color: #00437a; 
  --grey: #666;
  --black: #000;
  --white: #fff;
  --border-radius: 1rem;
  --border: 0.1rem solid rgba(0, 0, 0, .1);
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
  --body-bg: #fafafa;
  --gradient-gay: linear-gradient(90deg, rgba(250,250,251,1) 0%, rgba(236,237,237,1) 53%, rgba(223,224,225,1) 100%);
  --lightgreen: #37bba7;
  --border-radius: 1rem;
  --gutter-8: 8px;
  --borderRadius-8: 8px;
  --gutter-40: 50px;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('assets/fonts/AvenirNextLTPro-Regular.woff') format('woff'),
       url('assets/fonts/AvenirNextLTPro-Regular.ttf') format('truetype'),
       url('assets/fonts/AvenirNextLTPro-Regular.eot') format('eot');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('assets/fonts/AvenirNextLTPro-Bold.woff') format('woff'),
       url('assets/fonts/AvenirNextLTPro-Bold.ttf') format('truetype'),
       url('assets/fonts/AvenirNextLTPro-Bold.eot') format('opentype');
  font-weight: bold;
  font-style: normal;
}
body {
  font-family: 'Avenir Next LT Pro';
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--body-bg);
}
.lightgreen{
  color: var(--lightgreen);
}
.gradientGrayBg{
  background: var(--gradient-gay);
}
.centerElements{
  display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
}
/* Navigation Component */  

section{
  padding: 4rem 0;
}
.primary-title{
  color: var(--primary-color);
}
.primary-title span{
  color: var(--secondary-color);
}
.main-btn {
  background: var(--primary-color);
  text-transform: uppercase;
  color: #fff;
  padding: 0.6rem 1.4rem;
}
.main-btn:hover{
  border: 1px solid var(--primary-color);
  background: transparent!important;
  color: #000!important;
}
.fullBorder{
  border-radius: var(--border-radius);
}

/* Sub-menu styles */
.sub-menu {
  display: none; /* Hide sub-menu by default */
  position: absolute; /* Position sub-menu absolutely */
  top: 100%; /* Position sub-menu below its parent */
  left: 0; /* Align sub-menu with its parent */
  background-color: #fff; /* Background color of sub-menu */
  width: max-content;
  padding: 0;
  text-align: left;
  /* margin-top: 0.85rem; */
}

/* Show sub-menu when its parent is hovered */
.nav-item:hover .sub-menu {
  display: block;
}

/* Styles for sub-menu items */
.sub-menu li {
  list-style-type: none; /* Remove bullet points */
}

.sub-menu li a {
  display: block;
  padding: 0.7rem 1rem;
  text-decoration: none;
  color: #000!important;
  border-bottom: .1rem solid rgb(255 255 255 / 50%);
  transition: all .2s linear;
  text-transform: capitalize;
  display: flex;
  align-content: center;
  align-items: center;
}


.sub-menu li a:hover {
  border-color: var(--secondary-color);
  border-left: .2rem solid var(--secondary-color);
  color: #000;
  background: antiquewhite;
}
.sub-menu li a svg{
  width: 20px;
}
.sub-menu li a p{
  margin-bottom: 0;
  margin-left: 5px;
}
.prograIn-navbar .navbar-nav .nav-item:hover .sub-menu {
  display: block;
}

.megamenu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: none;
  z-index: 1000; /* Ensure it appears above other elements */
}

.customNavLink.nav-item:hover .megamenu {
  display: block; /* Show the mega menu on hover */
}

.megamenu .row {
  display: flex;
  justify-content: space-around;
}

.megamenu .col {
  flex: 1;
  padding: 10px;
  text-align: left;
}

.megamenu h5 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
  font-size: 0.9rem;
}

.megamenu ul {
  list-style: none;
  padding: 0;
}

.megamenu ul li {
  margin-bottom: 5px;
  color: #333;
}

.megamenu ul li a {
  color: #333!important;
  text-decoration: none;
  display: flex;
  align-content: center;
  align-items: center;
  border-radius: 10px;
}
.megamenu ul li a:hover {
background: antiquewhite;
}
.megamenu ul li a svg{
  width: 20px;
}
.megamenu ul li a p{
  margin-bottom: 0;
  padding-left: 5px;
  text-transform: capitalize;
  font-size: 0.9rem;
}
.customNavLink.nav-item.mega-menu:hover{
  position: unset;
}
.main-menu .navbar-nav{
  position: relative;
  padding-bottom: 0!important;
}


.top-nav{
  background: #28282B;
}
.prograIn-navbar {
  background-color: transparent;
  position: fixed;
  width: 100%;
  top: 1.5rem;
  justify-content: end;
  z-index: 9;
}
.prograIn-navbar .main-menu
{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background: #fff;
    width: 100%;
    display: flex;
    border-radius: 0.5rem;
    border: 1px solid #666666;
}
.main-menu .navbar-nav{
  padding: 0.5rem;
}
.custom-nav-logo {
  padding: 0.7em 0.5em 0.7em 0.8em;
}
.patchForMobileContainer {
  display: flex;
  padding: 0.5em 0em;
  gap: 1.2em;
  background-color: #28282B;
  justify-content: flex-end;
}
.patchForMobileContainer a {
  background-color: transparent;
  text-decoration: none;
  width: auto;
  height: auto;
  padding: 0px;
}
.patchForMobileContainer a i {
  color: #fff;
  background: transparent;
  width: auto;
  font-weight: 200 !important;
  line-height: 1 !important;
  font-size: 0.85em;
}
.custom-nav-logo img {
  width: 13em;
}
a.CloseNavigationMobile {
  display: none;
}
.customNavLink {
  padding: 0 10px;
  text-transform: uppercase;
  display: flex;
  line-height: 1.1;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 0.18rem solid var(--secondary-color);
  position: relative;
}
.customNavLink:last-child{
  border-right: unset;
}
.customNavLink a {
  padding: 0px;
  color: #000;
  font-weight: 500;
  padding: 10px 10px;
}
.customNavLink a i{
  margin-left: 5px;
}
/* .customNavLink.active {
  background-color: var(--primary-color);
  transition: all 0.25s ease-in-out;
  color: #fff;
}
.customNavLink.active a{
  color: #fff;
} */
.customNavLink:hover{
  /* transform: scale(1.08); */
  background-color: var(--primary-color);
  transition: all 0.25s ease-in-out;
  /* border: 1px solid #000; */
  color: #fff;
}
.customNavLink:hover a{
  color: #fff;
}
.customNavLink:hover .customNavLink.active{
  transform: unset!important;
  border: unset!important;
  background:transparent!important;
}
.contactfl-navigation-sticky-button {
  background-color: var(--primary-color);
    color: #fff;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 5px 1px 1px 5px;
    position: fixed;
    right: 0px;
    top: 11rem;
    padding: 5px 10px;
    padding-top: 1rem;
    line-height: 1.3;
    font-size: 11px;
    font-weight: 600;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.contactfl-navigation-sticky-button img{
  position: absolute;
  top: -2.2rem;
  width: 2.8rem;
}
.whatsapp-navigation-sticky-button{
  position: fixed;
  right: 0px;
  top: 15rem;
  z-index: 99;
}
.whatsapp-navigation-sticky-button img{
  width: 4em;
}

.sticky-buttons {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
  background-color: #f8f8f8;
  padding: 5px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.sticky-buttons a {
  text-decoration: none;
  color: #fff;
  padding: 7px 20px;
  border-radius: 5px;
  text-align: center;
  flex: 1;
  margin: 0 10px;
  max-width: 150px;
}
.sticky-buttons img{
  width: 20px;
    margin-right: 5px;
    margin-top: -5px;
}
.contact-us-btn {
  background-color: var(--primary-color);
}

.whatsapp-btn {
  background-color: #25d366;
}

.taglineText{
  background: #142131;
    text-align: center;
    padding: 0.7rem;
}
.taglineText p{
  color: #fff;
    margin-bottom: 0;
    font-size: 22px;
    font-style: italic;
    font-weight: 600;
}
/* Home Page */
.homePageContainer {
  margin-top: 2rem;
}
/* Slider */
.sliderForHeaderContainer {
  position: relative;
}
.sliderForHeaderContainer .banner-desc{
  display: flex;
  align-items: center;
}
.sliderForHeaderContainer .banner-desc h1{
  font-style: italic;
  font-weight: 600;
  color: var(--primary-color);
}
.sliderForHeaderContainer .banner-desc p{
  font-size: 20px;
  margin-top: 1rem;
}
.sliderForHeaderContainer .banner-desc .main-btn{
  background: var(--primary-color);
  text-transform: uppercase;
  color: #fff;
  margin-top: 1rem;
  padding: 0.6rem 1.4rem;
}
.sliderForHeaderContainer .banner-desc h1 span{
 color: var(--secondary-color);
}
/* CSS for navigation arrows */
.sliderForHeaderContainer .slick-prev, .sliderForHeaderContainer .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background-color: transparent; /* Arrow background color */
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.sliderForHeaderContainer .slick-prev:hover, .sliderForHeaderContainer .slick-next:hover {
  background-color: transparent; /* Arrow background color on hover */
}

.sliderForHeaderContainer .slick-prev {
  left: 20px; /* Adjust distance from left */
}

.sliderForHeaderContainer .slick-next {
  right: 20px; /* Adjust distance from right */
}
.sliderForHeaderContainer .slick-prev:before{
  position: absolute;
  left: 0;
  top: 0;
}
.sliderForHeaderContainer .slick-next:before {
  position: absolute;
  right: 0;
  top: 0;
}
.sliderForHeaderContainer .slick-prev:before, .sliderForHeaderContainer .slick-next:before {
  font-size: 45px;
  font-weight: bolder;
}

.heading {
  padding-bottom: 2rem;
  text-align: center;
}
.heading h2 {
  color: var(--secondary-color);
  font-size: 2.5em;
  font-weight: 500;
  padding-bottom: .5rem;
  position: relative;
  text-transform: capitalize;
}
.heading h2:after, 
.heading h2:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45px;
    height: 4px;
    content: "";
    right: 45px; 
    margin: auto;
    background-color: #ccc;
}
.heading h2:before { 
  background-color: var(--main-color);
  left: 45px; 
  width: 90px;
}

.box-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.slick-dots li button:before{
  background: #999;
    border: .1rem solid rgba(0, 0, 0, .1);
    border: var(--border);
    border-radius: .2rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.1);
    box-shadow: var(--box-shadow);
    height: 0.6rem!important;
    font-size: unset !important;
    opacity: .7;
    width: 1rem!important;
    transition: all .2s linear;
}
.slick-dots li.slick-active{
  width: 2rem!important;
  background: var(--main-color) !important;
  opacity: 0.9 !important;
  height: 0.6rem !important;
  border-radius: .2rem;
  
}
.slick-dots li{
  transition: all .2s linear;
}
.slick-dots li.slick-active button:before{
  width: 2rem!important;
  background: var(--main-color) !important;
  
}


/* About Section */
.about .heading {
  padding-bottom: 2rem;
  text-align: center;
}
.vid-bg {
  height: 450px;
}
.vid-bg video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.vid-overlay {
  background: rgb(11, 12, 25);
  background: linear-gradient(0deg, rgba(11, 12, 25, .5) 25%, rgba(251, 251, 251, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.position-relative {
  position: relative !important;
}
.about .heading h2 {
  color: var(--secondary-color);
  font-size: 2.5em;
  font-weight: 500;
  padding-bottom: .5rem;
  position: relative;
  text-transform: capitalize;
}
.backshadow{
  box-shadow: 0 .5rem .75rem #1632451a !important;
}
.border1{
  border-radius: var(--border-radius);
}
.about .heading h2:after, 
.about .heading h2:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45px;
    height: 4px;
    content: "";
    right: 45px; 
    margin: auto;
    background-color: #ccc;
}
.InnerBanner svg{
  position: absolute;
  top: 0;
}
.InnerBanner.gee1I {
  position: relative;
    display: flex;
    align-items: center;
    padding: 200px 0 150px;
    text-align: left;
    z-index: 1;
    background: #fff;
}
.InnerBanner .odzpY {
  background-color: var(--primary-color);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  clip-path: polygon(0 0, 100% 0%, 100% 133%, 0 -150%);
  object-fit: cover;
  filter: brightness(.5);
  z-index: -1;
}
.InnerBanner h2{
  font-size: 1.5rem;
  color: var(--primary-color);
}
.InnerBanner h1{
  font-weight: 600;
}
.logopng{
display: flex;
align-content: center;
align-items: center;
margin-bottom: 1.5rem;
}
.logopng span{
  font-size: 45px;
  padding: 10px;
}
.aboutInfo .logopng.bottom img{
  min-width: 15em;
}
/* Heading Before styles */
.about .heading h2:before { 
    background-color: var(--main-color);
    left: 45px; 
    width: 90px;
}
.about .box-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}
.about .image {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.about .contentSec {
  flex: 1;
}
.about .box-container .content p {
  color: var(--grey);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: .5px;
  line-height: 1.6;
}
.about .contentSec .btn{
    color: var(--black);
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #000;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px;
    transition: background-color .3s, color .3s;
    text-transform: capitalize;
}
.about .contentSec .btn:hover {
  color: #fff;
  background-color: #000;
}

/* Why PL */
.target-pent {
  background-repeat: no-repeat;
  min-height: 250px;
  margin-top: 55px;
  padding: 150px 0 0 0;
  background-position: center top 50px !important;
}
.target-pent p {
  font-size: 30px;
  color: var(--primary-color);
  line-height: 35px;
  font-weight: bold;
}
.target-pent b {
  color: var(--secondary-color);
}
.youtube-intro{
  position: relative;
}
.youtube-intro::before {
  content: ''; /* Required for the ::before pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('assets/images/logo-shape.png') !important;
  background-position: left -448px top 50% !important;
  background-size: 531px auto !important;
  background-repeat: no-repeat !important;
}
.youtube-intro::after {
  content: ''; /* Required for the ::before pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('assets/images/logo-shape.png') !important;
  background-position: right -448px top 50% !important;
  background-size: 531px auto !important;
  background-repeat: no-repeat !important;
}
/* .new-how-work .how-it-work-shape {
  background: url('assets/images/new-pent.png');
  background-size: 100% auto;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  min-height: 365px;
  align-items: center;
  margin-top: 30px;
    display: flex;
    justify-content: CENTER;
    font-size: 45px;
    font-weight: 700;
    font-family: poppins;
    color: #000;
    margin-bottom: 20px;
} */
.new-how-work .how-it-work-shape p {
  font-size: 20px;
    color: var(--primary-color);
    font-weight: 600;
    max-width: 210px;
    line-height: 24px;
    text-align: center;
}
.how-it-work-shape-div{
  padding: 0 4rem;
}
.AS .ASImg{
  padding: 0 3rem;
}
.how-it-work-shape-div .flex-container{
  display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    align-items: center;
}
.how-it-work-shape-div .flex-container img{
  width: 5em;
}
.how-it-work-shape-div .flex-container h4{
  font-size: 2.5rem;
  font-weight: 600;
}
._ECS_box{
  border: 1px solid var(--primary-color);
  padding: 1rem;
  border-radius: var(--borderRadius-8);
  height: 100%; 
}
._ECS_box .F7NNY span {
  background-color: #fafafa;
  width: 4rem;
  height: 4rem;
  padding: var(--gutter-8);
  border-radius: var(--borderRadius-8);
}
._ECS_box .F7NNY {
  display: flex;
  justify-content: end;
  margin-top: calc(var(--gutter-40)* -1);
}
._ECS_box .desc{
  font-size: 1.2rem;
}
._ECS_SOT_box ._SOT{
  display: flex;
}
._ECS_SOT_box ._SOT .item:first-child{
  width: 4.5rem;
  margin-right: 0.5rem;
}
._ECS_SOT_box ._SOT .item p{
  font-size: 1.2rem;
  margin-bottom: 0;
}
._ECS_DSP_box .item:first-child{
  width: 4.5rem;
  margin-right: 0.5rem;
}
._ECS_DSP_box .item p{
  margin-left: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-top: 0.5rem;
}
._SOT.FI svg{
  width: 60px !important;
}
._SOT.FI .item:first-child{
  width: unset;
}
._ECS_SOT_box ._SOT.FI .item{
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactInfoDiv{
  box-shadow: 0px 4px 8px #1212120d, 0px 0px 8px #1212120d;
  margin-right: 3.5rem;
  padding: 2rem;
}
.contactInfoDiv .getInTouchLinks{
  list-style: none;
  padding: 0;
}
.contactInfoDiv .getInTouchLinks .footerParaText{
  color: #212529;
}
.contactInfoDiv .getInTouchLinks li{
  margin-bottom: 10px;
    display: flex;
    align-content: flex-end;
    align-items: flex-start;
    gap: 0.5rem;
}
.contactInfoDiv .getInTouchLinks li .box.location{
  width: 32px;
  text-align: center;
}
.contactInfo form .btn{
  background: var(--secondary-color);
  border: none;
}
.contactInfo form .form-control{
  background-color: #00437a12;
}
.contactInfoDiv a{
  text-decoration: none;
  color: #212529;
}
.ECS_Features_Tbl .Qdn8C {
  color: #40d4ac;
}
.ECS_Features_Tbl .Qdn8C svg, .Xyh-g svg{
  -webkit-user-select: none;
    user-select: none;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
}
.ECS_Features_Tbl .Xyh-g {
  color: #ff5757;
}
.NMTP ._ECS_box .F7NNY span {
  background-color: #fff;
}

.ECS_Features_Tbl .table>:not(caption)>*>*{
  padding: 1.5rem;
}
.ECS_Features_Tbl thead th{
  background-color: var(--primary-color);
  color: #fff;
  font-size: 1.2rem;
}
.ECS_Features_Tbl .table>thead {
  vertical-align: middle;
}
.ECS_Features_Tbl tbody td{
  text-align: left;
}
.ECS_Features_Tbl tbody th{
 font-weight: 500;
 font-size: 1rem;
}
.ACS ._ECS_box .F7NNY{
  justify-content: center;
    background-color: var(--secondary-color);
    text-transform: uppercase;
    border-top-right-radius: 15px;
    padding: 10px;
    border-bottom-left-radius: 15px;
}
.ACS ._ECS_box{
  padding-top: 1.6rem;
}
.ACS ._ECS_box .F7NNY h3{
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #fff;
}
.ACS ._ECS_box .planDesc ul{
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.PSF ._ECS_box .F7NNY{
  justify-content: flex-start;
    width: max-content;
    padding: 5px 25px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background: var(--secondary-color);
}
.PSF ._ECS_box .F7NNY h3 {
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #fff;
}
.PSF ._ECS_box{
  padding-top: 1.85rem;
}
.PSF ._ECS_box .number{
  font-size: 4.5rem;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
}
.PSF ._ECS_box .number.uptotag{
  line-height: 1.2;
}
.PSF ._ECS_box .number small{
  font-size: 1.7rem;
  width: 100%;
  font-weight: 400;
}
.PSF ._ECS_box .planDesc {
display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}
.PSF ._ECS_box .planDesc p{
  font-size: 1.2rem;
}
.PSF ._ECS_box .comLogo img{
  width: 7em;
}
.PSF ._ECS_box .planDesc .conten{
  min-width: 8em;
}
.PSF .spacingM{
  margin-top: 4.6rem;
}
.getRedirectionBtn{
  font-size: 13px;
  color: #fff;
  border: 1px solid #fff;
  margin-top: 10px;
  padding: 4px 10px;
}
.getRedirectionBtn:hover{
  border: 1px solid #fff;
}

.platformSec .discription{
  display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.gnagt{
  margin: 4rem 0;
}

.platformSec .partnersBrand-logo {
  display: flex;
  flex-flow: row wrap;
  gap: 0.2rem;
  margin-top: 1.5rem;
}
.platformSec .partnersBrand-logo .item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(32% - 4.2rem);
  margin: 2px;
}
.platformSec .partnersBrand-logo .item img{
  width: 8em;
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.25), 0 4px 6px rgba(0, 0, 0, 0.22);
}
.partnerSec .parner_logo{
  width: 10em;
  margin: auto;
}
.partnerSec .parner_logo img{
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.SSPSec ul li::marker {
  font-size: 1.5rem;
}
.SSPSec ul li p{
  margin-bottom: 0;
  font-size: 1rem;
}
.SSPSec ul li{
  margin-bottom: 0.5rem;
}
.SSPSec .SSPImg{
  padding: 2.5rem;
}
.SSPSec .SSPImg img{
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.CTSec .cardDiv{
  padding: 2.5rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--borderRadius-8);
  height: 100%;
}
.boxDiv .cardDiv{
  padding: 1rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--borderRadius-8);
  height: 100%;
  position: relative;
  z-index: 1; 
}
.boxDiv .cardDiv h3{
  text-align: center;
  font-size: 1.2rem;
}
.boxDiv .cardDiv img{
  width: 8em;
}
.boxDiv .cardDiv:before {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  z-index: -1;
  height: 0%;
  position: absolute;
  background: antiquewhite;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: var(--borderRadius-8);
}
.boxDiv .cardDiv:hover:before {
  height: 100%;
}
.boxDiv .cardDiv p{
  text-align: center;
}

.otherpageTitle{
  font-size: 2.5rem;
}
.discription {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
/* End PL */
.InnerBSep{
  height: 4px;
    width: 100%;
background: linear-gradient( 90deg, #ff5757 -.8%, #ffa200 45.48%, #40d4ac 98.71% )
}

.abtCollabLogo{
  padding: 0 4rem;
}
.abtCollabLogo img{
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.ReadyDeploy h2{
  color: var(--primary-color);
}
.ReadyDeploy .itembox{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ReadyDeploy .main-btn{
  background: var(--primary-color);
    text-transform: uppercase;
    color: #fff;
    margin-left: 4rem;
    padding: 0.6rem 1.4rem;
}
.ReadyDeploy{
  background: var(--gradient-gay);
}
.ATC{
  background: var(--gradient-gay);
}

.ATC .overlap-end {
    position: relative;
    display: flex;
    justify-content: end;
}
.ratio {
  position: relative;
  width: 70%;
}
.shadow-md {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.overlap-end .overlap-content {
  left: 17%;
}
.overlap-end .overlap-content {
  position: absolute;
  bottom: -15%;
}
.overlap-end .ratio video{
  z-index: 2;
}
.overlap-end .ratio .backImg{
  z-index: 2;
}
/* .borderLayout {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px solid #000;
    border-radius: 1rem;
    animation-delay: 1s;
    animation-duration: 1.2s;
    transition: all 0.25s ease-in-out;
} */
.borderLayout.top{
  /* top: -10px;
  left: 10px; */
}
.borderLayout.bottom{
  /* bottom: -10px;
  left: -10px; */
}
.overlap-content .videoRatio{
  width: 300px;
  height: auto;
  position: relative;
}
.overlap-content .videoRatio video{
  position: relative;
  z-index: 2;
}
.Contextual .overlap-end{
  position: relative;
}
.Contextual .overlap-content .videoRatio{
  z-index: 2;
}
.Contextual .overlap-end .overlap-content{
  right: 0%;
  left: unset;
}

.ExploreAudience .overlap-content .videoRatio{
  z-index: 2;
}
.ExploreAudience .overlap-end .overlap-content{
  right: 0%;
  left: unset;
}

.CrossDevice .overlap-end {
  position: relative;
  display: flex;
  justify-content: end;
}


/* Our Sponsers */
.OurSponsers .slide{
  text-align: center;
  box-shadow: 0 0 10px rgba(141, 132, 132, 0.1); 
  border: 1px solid #ddd; 
}

.OurSponsers .slide img {
  display: inline-block;
  max-width: 100%; 
  height: auto; 
}
.OurSponsers .slick-slide {
  display: flex;
  align-items: center;
  padding: 10px;
}

.OurSponsers .slick-dots li button:before{
  background: #999;
    border: .1rem solid rgba(0, 0, 0, .1);
    border: var(--border);
    border-radius: .2rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.1);
    box-shadow: var(--box-shadow);
    height: 0.6rem!important;
    font-size: unset !important;
    opacity: .7;
    width: 1rem!important;
    transition: all .2s linear;
}
.OurSponsers .slick-dots li.slick-active{
  width: 2rem!important;
  background: var(--main-color) !important;
  opacity: 0.9 !important;
  height: 0.6rem !important;
  border-radius: .2rem;
  
}
.OurSponsers .slick-dots li{
  transition: all .2s linear;
}
.OurSponsers .slick-dots li.slick-active button:before{
  width: 2rem!important;
  background: var(--main-color) !important;
  
}
/* End Our Sponser */

/* Start Upcoming Events */
.UpcomingEvents{
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./assets/images/event-bg.jpg");
  background:         linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./assets/images/event-bg.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed; 
} 

/* Grid container for upcoming items */
.UpcomingEvents .box-container{
  display: -ms-grid; 
  display: grid;
  -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 1rem;
}

/* Each upcoming item */
.UpcomingEvents .upcoming-item{
  border: 0.2rem solid var(--main-color);
  text-align: center;
}

/* Upcoming item Content */
.UpcomingEvents .upcoming-item .content{
  padding: 2rem 1rem;
}

/* Event Time */
.UpcomingEvents .upcoming-item .time{
  font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--black);
    text-align: start;
    padding: 0.2rem 0.5rem;
    border-bottom: 1px dashed #000;
}

/* Team Content Section */
.UpcomingEvents .upcoming-item .team-content{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


/* Team Logo */
.UpcomingEvents .upcoming-item img{
  width: 100%;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

/* Team Name */
.UpcomingEvents .upcoming-item h3{
  font-size: 1.3rem;
    text-align: left;
    color: var(--black);
    border-bottom: 1px dashed #000;
    padding: 0 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0;
    display: flex;
}
.UpcomingEvents .upcoming-item h3 i{
  margin-top: 5px;
  margin-right: 10px;
}

/* Styling for "vs" */
.UpcomingEvents .upcoming-item h6{
  font-size: 6rem;
  font-weight: 600;
  color: var(--black);
}

/* Event Venue */
.UpcomingEvents .upcoming-item .venue{
  font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--black);
    text-align: start;
    padding: 0.2rem 0.5rem;
}

/* Button Container */
.UpcomingEvents .upcoming-item .btn-container{ 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Buttons */
.UpcomingEvents .upcoming-item .btn{
  width: 100%;
  text-align: center;
}

.UpcomingEvents .btn {
  background: var(--main-color);
    /* box-shadow: var(--box-shadow); */
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 0;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  transition: all .5s ease-in-out;
}
.UpcomingEvents .upcoming-item .btn-container a:first-child{
  border-right: 1px dashed #000;
}

/* End Upcoming Events */

/* Start Home Award */
.HomeAwards {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./assets/images/event-bg.jpg");
  background:         linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./assets/images/event-bg.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed; 
}
.HomeAwards .slide{
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  background: #fce3228f;
}
.HomeAwards .slick-slide {
  display: flex;
  align-items: center;
  padding: 10px;
}
/* End Home Award */

/* Start Top Players */
.TopPlayes .slide .team-item {
  border-bottom: .4rem solid var(--main-color);
  overflow: hidden;
  position: relative;
}
.TopPlayes .slide img{
  width: 100%;
}
.TopPlayes .slide .playerName{
  position: absolute;
    bottom: 0;
    background: #0000009c;
    width: 100%;
    color: #fff;
    text-align: center;
    padding-top: 5px;
}
.TopPlayes .slide .playerName h2{
font-size: 1.5rem;
}
.TopPlayes .slide .playerName h5{
  font-size: 1rem;
}

.TopPlayes .slick-slide {
  display: flex;
  align-items: center;
  padding: 10px;
}

/* End Top Playeer */

/* Choose Us Section */
.ChooseUsContainer {
  padding: 4rem 3em;
  background-color: #fce322;
  text-align: center;
  position: relative;
}
.HeadText {
  font-size: 2.5em;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 0.5em;
}
.chooseUsSubSection {
  display: flex;
}
.chooseusImage {
  width: 300px;
  height: fit-content;
  position: absolute;
  bottom: 0;
}
.ParaText {
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.ParaText span {
  margin-bottom: 0.8em;
}
/* Footer */
.prograInFooterContainer {
  padding: 2em 7em 3em 7em;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.prograInFooterContainer .brand-logo img{
  width: 18em;
  margin-bottom: 2rem;
}

.prograInFooter {
  padding-bottom: 15px;
    border-bottom: 1px solid #fff;
    margin-bottom: 10px;
}
.FooterAboutus {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: end;
}
.FooterLegalPrivacy {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: end;
}
.FooterGitTouch {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: end;
}
.footerHeadText {
  font-size: 1.05em;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 0.8em;
}
.footerParaText {
  font-size: 0.95em;
  color: #fff;
  text-decoration: none;
  margin: 0;
  margin-bottom: 0.5em;
  width: inherit;
}
.prograInFooter .pages {
  display: flex;
  gap: 2rem;
}
/* .prograInFooter .pages .links-item {
  flex: 1 1 15rem;
} */
.prograInFooter .info p {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.prograInFooter .links p .icon {
  color: #fff;
  font-size: 0.7rem;
}
.prograInFooter .pages .links-item a{
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
}
.prograInFooter .pages .links-item p:hover a{
  color: var(--secondary-color);
}
.prograInFooter .getInTouchLinks{
  list-style: none;
  color: #fff;
  padding: 0;
}
.prograInFooter .getInTouchLinks li{
  margin-bottom: 10px;
  display: flex;
  align-content: flex-end;
  align-items: flex-start;
}
.prograInFooter .getInTouchLinks li i{
  margin-right: 8px;
  margin-top: 5px;
}

.copyright {
  justify-content: start;
  gap: 1em;
}
p.footerParaText02 {
  font-size: 1em;
  color: #fff;
  font-weight: 100;
  margin: 0;
}
.LineVertical {
  width: 0.5px;
  height: 1.5em;
  background: #fff;
}
.footerHeadText02 {
  font-size: 1.05em;
  color: #fce322;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  text-align: end;
}
.footerHeadSubText{
  color: #fff;
  font-size: 14px;
}
.socialmediaConatiner {
  justify-content: flex-start;
  gap: 2em;
  display: flex;
  align-items: center;
}
.socialmediaLinksContainer {
  gap: 1em;
}
.socialmediaLinksContainer a {
  text-decoration: none;
}
.socialmediaLinksContainer .fa-linkedin-in{
  background: #0060cf;
  color: #fff;
  box-shadow: rgb(50 50 93 / 51%) 0px 6px 12px -2px, rgb(0 0 0 / 50%) 0px 3px 7px -3px;
}
.socialmediaLinksContainer .fa-facebook-f{
  background: #2532b3;
  color: #fff;
  box-shadow: rgb(50 50 93 / 51%) 0px 6px 12px -2px, rgb(0 0 0 / 50%) 0px 3px 7px -3px;
}
.socialmediaLinksContainer .fa-instagram{
  background-image: url("assets/images/insta-bg.png");
  color: #fff;
  background-size: cover;
  box-shadow: rgb(50 50 93 / 51%) 0px 6px 12px -2px, rgb(0 0 0 / 50%) 0px 3px 7px -3px;
}
.socialmediaLinksContainer .fa-x-twitter{
  background: #000;
  color: #fff;
  background-size: cover;
  box-shadow: rgb(50 50 93 / 51%) 0px 6px 12px -2px, rgb(0 0 0 / 50%) 0px 3px 7px -3px;
}
.socialmediaLinksContainer .fa-youtube{
  background: #e10000;
  color: #fff;
  background-size: cover;
  box-shadow: rgb(50 50 93 / 51%) 0px 6px 12px -2px, rgb(0 0 0 / 50%) 0px 3px 7px -3px;
}
.socialmediaLinksContainer .fa-whatsapp{
  background: #36e558;
  color: #fff;
  background-size: cover;
  box-shadow: rgb(50 50 93 / 51%) 0px 6px 12px -2px, rgb(0 0 0 / 50%) 0px 3px 7px -3px;
}

.footerIcons {
  color: #111;
  background: #fce322;
  line-height: 0.7 !important;
  border-radius: 50%;
  width: 1.75em;
  height: 1.75em;
  font-weight: 500 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.prograInFooter .partnersBrand-logo{
  display: flex;
  flex-flow: row wrap;
  gap: 0.2rem;
  margin-bottom: 1.5rem;
}
.prograInFooter .partnersBrand-logo .item{
  flex: 0 0 calc(32% - 0.2rem);
  margin: 2px;
}
.prograInFooter .partnersBrand-logo .item img {
  width: 100%;
}
.content {
  width: 100%;
  height: 35px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
  border-radius: 60px;
  overflow: hidden;
}

.subscription {
  position: relative;
  width: 100%;
  height: 100%;
}

.subscription .add-email{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 20px;
}

.subscription .submit-email {
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 2px);
  width: 80px;
  border: none;
  border-radius: 60px;
  outline: none;
  margin: 1px;
  padding: 0 20px;
  font-size: 14px;
  cursor: pointer;
  background: #f60000;
  color: #FFFFFF;
  transition: width .35s ease-in-out,
    background .35s ease-in-out;
}

.subscription.done .submit-email {
  width: calc(100% - 2px);
  background: #C0E02E;
}

.subscription .submit-email .before-submit,
.subscription .submit-email .after-submit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 30px;
  transition: visibility .35s ease-in-out,
    opacity .35s ease-in-out;
}

.subscription.done .submit-email .before-submit,
.subscription:not(.done) .submit-email .after-submit {
  visibility: hidden;
  opacity: 0;
}

.subscription .submit-email .after-submit {
  transition-delay: .35s;
}

.subscription:not(.done) .submit-email .before-submit,
.subscription.done .submit-email .after-submit {
  visibility: visible;
  opacity: 1;
}
@keyframes easeInAnimation-right {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.contact-form{
  position: relative;
}
.loaderOverlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffbf;
    top: 0;
    border-radius: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    display: none;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333; /* Change the color of the spinning animation */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-left: 10px; /* Adjust the margin as needed */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(223, 223, 223);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  display: none;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 400px;
  background-color: white;
}

.popup-inner .close-btn {
  position: absolute;
    top: -10px;
    right: -5px;
    background-color: rgb(4, 77, 120);
    padding: 2px 8px;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    border: 0;
}

.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
}

.loader_main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 0;
  color: #00c9d0;
  display: inline-block;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.lead{
font-size:13px;
}
.loader_main div {
  background-color: #ff5854;
  display: inline-block;
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  opacity: .5;
  border-radius: 50%;
  -webkit-animation: ballPulseDouble 2s ease-in-out infinite;
  animation: ballPulseDouble 2s ease-in-out infinite;
}

.loader_main div:last-child {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes ballPulseDouble {
  0%,
  100% {
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  50% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}

@keyframes ballPulseDouble {
  0%,
  100% {
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  50% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}